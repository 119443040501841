import A11yDialog from 'a11y-dialog'
import mobile from 'is-mobile';
import { setCookie, getCookie } from '../../utilities/utilities';

export default class Popin {
  constructor(element) {
    this.element = element;
    this.delay;
    this.device;
    this.days;
    this.dialog;
    this.trigger;

    this.handleHide = this.handleHide.bind(this);
  }

  hasCookie() {
    return getCookie(this.element.id) !== "";
  }

  mount() {
    this.dialog = new A11yDialog(this.element);

    this.dialog.on('show', (element, event) => {
      if(event) {
        this.trigger = event.target;
      }
    });

    const popinLinks = this.element.getElementsByTagName('a');
    for (let i = 0; i < popinLinks.length; i++) {
      popinLinks[i].addEventListener('click', this.handleHide);
    }

    this.dialog.on('hide', this.handleHide);

    if ('popinDelay' in this.element.dataset) {
      this.delay = parseInt(this.element.dataset.popinDelay, 10) * 1000;
    }

    if ('popinDevice' in this.element.dataset) {
      this.device = this.element.dataset.popinDevice;
    }

    if ('popinDays' in this.element.dataset) {
      this.days = parseInt(this.element.dataset.popinDays, 10);
    }

    const cookie = getCookie(this.element.id);
    if(!cookie) {
      const isMobile = mobile();
      if (
        this.device === "all" ||
        ( this.device == 'mobile' && isMobile ) ||
        ( this.device == 'desktop' && !isMobile )
      ) {
        if(this.delay) {
          const loop = () => {
            if(this.delay) {
              const tmp = Date.now();
              const delta = tmp - this.timestamp;

              if(delta > this.delay) {
                this.dialog.show();
                this.delay = false;
              }

              requestAnimationFrame(loop);
            }
          };

          this.timestamp = Date.now();
          loop();
        } else {
          this.dialog.show();
        }
      }
    }
  }

  unmount() { }

  handleHide() {
    if(!this.trigger) {
      setCookie(this.element.id, 'true', this.days);
    } else {
      this.trigger = null;
    }
  }
}
