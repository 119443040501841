import 'mdn-polyfills/NodeList.prototype.forEach';
import Newsletter from './Newsletter';

document.addEventListener('DOMContentLoaded', () => {
  const newsletters = document.querySelectorAll('.js-newsletter');
    if (!newsletters.length) {
      return;
    }
    newsletters.forEach((element) => {
      const newsletter = new Newsletter(element);
      newsletter.mount();
    });
});
