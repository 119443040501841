export default class Contact {
  constructor(element) {
    this.element = element;
    this.inputs = this.element.querySelectorAll('.c-form__field--service input[type="radio"]');
    this.target = this.element.querySelector('.c-form__field--serial_number');
    this.current = null;

    this.handleInputsChange = this.handleInputsChange.bind(this);
  }

  handleInputsChange(e) {
    this.current = e.target.value;
    this.update();
  }

  update() {
    if (this.current === 'sav') {
      this.target.removeAttribute('hidden');
    } else {
      this.target.setAttribute('hidden', '');
    }
  }

  mount() {
    const active = this.element.querySelectorAll('.c-form__field--service input[type="radio"]:checked');

    if(active.length > 0) {
      this.current = active.value;
    }
    this.update();

    this.inputs.forEach(input => {
      input.addEventListener('change', this.handleInputsChange);
    });
  }

  unmount() {
    this.target.removeAttribute('hidden');

    this.inputs.forEach(input => {
      input.removeEventListener('change', this.handleInputsChange);
    });
  }
}
