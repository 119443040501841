import Toggler from '../toggler/Toggler';

export default class Dropdown {
  constructor(element) {
    this.element = element;
    this.control = this.element.querySelector('[data-dropdown-control]');
    this.target = document.getElementById(this.control.dataset.dropdownControl);
    this.toggler = new Toggler(this.control, this.target, true);
  }

  mount() {
    this.toggler.mount();
  }

  unmount() {
    this.toggler.unmount();
  }
}
