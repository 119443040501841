import 'mdn-polyfills/NodeList.prototype.forEach';
import Popin from './Popin';

document.addEventListener('DOMContentLoaded', () => {
  const popins = document.querySelectorAll('[data-popin]');
    if (!popins.length) {
      return;
    }
    popins.forEach((element) => {
      const popin = new Popin(element);
      popin.mount();
    });
});
