export default class Newsletter {
  constructor(element) {
    this.element = element;
    this.form = this.element.querySelector('.js-newsletter-form');
    this.feedback = this.element.querySelector('.js-newsletter-feedback');
    this.isFetching = false;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if(!this.isFetching) {
      this.isFetching = true;

      const formData = new FormData(this.form);
      formData.append('format', 'json');

      window.fetch(this.form.action, {
        method: 'POST',
        cache: 'default',
        body: formData,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }

          return response.json();
        })
        .then((json) => {
          this.isFetching = false;
            if (json.success) {
              this.feedback.removeAttribute('hidden');
              this.form.remove();
            }
        })
        .catch((e) => {
          this.isFetching = false;
          console.error(e);
        });
      }
  }

  mount() {
    this.form.addEventListener('submit', this.handleFormSubmit);
  }

  unmount() {
    this.form.removeEventListener('submit', this.handleFormSubmit);
  }
}
