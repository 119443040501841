import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isNaN';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.swiperElement = this.element.querySelector('[data-slider-swiper]');
    this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.prev = this.element.querySelector('[data-slider-control="prev"]');
    this.next = this.element.querySelector('[data-slider-control="next"]');
    this.pagination = this.element.querySelector('[data-slider-pagination]');
    this.settings = {
      navigation: { nextEl: this.next, prevEl: this.prev },
      pagination: { el: this.pagination, clickable: true },
      slidesPerView: 'auto'
    };
    this.swiper;
  }

  mount() {
    Swiper.use([Navigation, Pagination]);

    if(this.element.dataset.slider === 'infinite') {
      this.settings = {
        ...this.settings,
        loop: true,
      }
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
