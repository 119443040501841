import 'mdn-polyfills/NodeList.prototype.forEach';
import { debounce } from '../../utilities/utilities';
import Toggler from '../toggler/Toggler';
import Navigation from '../navigation/Navigation';

export default class Header {

  constructor(element) {
    this.element = element;
    this.top = this.element.querySelector('#header-top');
    this.main = this.element.querySelector('#header-main');
    this.navigationTogglers = [];
    this.tmp = 0;
    this.height = this.element.offsetHeight;
    this.isSticky = false;
    this.isPinned = false;
    this.navigation = null
    this.navigationToggler = null;
    this.hasNavigationActive = false;
    this.hasOverlay = false;
    this.countOverlay = 0;
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop || document.body.scrollTop || window.pageYOffset || 0;

    this.handleNavigationHide = this.handleNavigationHide.bind(this);
    this.handleNavigationShow = this.handleNavigationShow.bind(this);
    this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handlePinned = this.handlePinned.bind(this);
    this.handleDebouncedResize = debounce(this.handleDebouncedResize.bind(this), 100);
  }

  handlePinned() {
    this.element.removeEventListener('transitionend', this.handlePinned);
    this.element.classList.remove('is-pinned');
    this.element.classList.remove('is-leaving');
    this.isPinned = false;
  }

  handleNavigationHide() {
    this.element.classList.remove('has-navigation-active');
    this.hasNavigationActive = false;

    this.navigation.togglers.forEach((toggler) => {
      toggler.hide(true);
    });

    this.navigationToggler.focus();
    this.navigationToggler = null;
    this.update();
  }

  handleNavigationShow(e) {
    this.navigationToggler = e.detail.toggler.element;
    this.element.classList.add('has-navigation-active');
    this.hasNavigationActive = true;
    this.update();
  }

  handleNavigationUpdate() {
    this.update();
  }

  updateProps() {
    let topHeight = 0;
    let mainHeight = 0;

    if (this.top) {
      topHeight = this.top.offsetHeight;
      document.documentElement.style.setProperty('--header-top-height', `${topHeight}px`);
    }
    if (this.main) {
      mainHeight = this.main.offsetHeight;
      document.documentElement.style.setProperty('--header-main-height', `${mainHeight}px`);
    }

    this.height =this.element.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${ this.height }px`);
  }

  handleScroll() {
    if(!this.hasNavigationActive) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || document.body.scrollTop || window.pageYOffset || 0;

      if(scrollTop >= this.scrollTop) {
        this.direction = 'down';
      } else {
        this.direction = 'up';
      }

      if (scrollTop > this.height) {
        this.element.classList.add('is-sticky');
        this.isSticky = true;
      } else {
        this.element.classList.remove('is-sticky');
        this.element.classList.remove('is-pinned');
        this.isSticky = false;
        this.isPinned = false;
      }

      if(this.isSticky) {
        if (this.direction === 'up') {
          this.element.classList.add('is-pinned');
          this.isPinned = true;
        } else {
          if(this.isPinned) {
            this.element.addEventListener('transitionend', this.handlePinned);
            this.element.classList.add('is-leaving');
          }
        }
      }

      this.scrollTop = scrollTop;
    }
  }

  handleDebouncedResize() {
    if (this.tmp !== this.element.offsetWidth) {
      this.navigationTogglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(toggler.element).getPropertyValue('display') !== 'none';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
            this.element.classList.remove('has-navigation-active');
            this.hasNavigationActive = false;
          }
        }
      });

      this.updateProps();
      this.tmp = this.element.offsetWidth;
    }
  }

  update() {
    let isNavigationHasTogglerExpanded = false;

    this.navigation.togglers.forEach((toggler) => {
      if (!toggler.target.contains(toggler.element)) {
        isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
      }
    });

    if (isNavigationHasTogglerExpanded && !this.hasOverlay) {
      document.body.classList.add('has-overlay');
      this.hasOverlay = true;
    } else if (!isNavigationHasTogglerExpanded && this.hasOverlay) {
      document.body.classList.remove('has-overlay');
      this.hasOverlay = false;
    }
  }

  mount() {
    this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
      const target = document.getElementById(element.dataset.headerToggler);
      const toggler = new Toggler(element, target);
      this.navigationTogglers.push(toggler);
      element.addEventListener('toggler:hide', this.handleNavigationHide);
      element.addEventListener('toggler:show', this.handleNavigationShow);

      if (window.getComputedStyle(element).getPropertyValue('display') !== 'none') {
        toggler.mount();
      }
    });

    const nav = this.element.querySelector('#navigation');
    if (nav) {
      this.navigation = new Navigation(nav);
      this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.mount();
    }

    if(this.top && this.main) {
      window.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('resize', this.handleDebouncedResize);
    this.handleDebouncedResize();
  }

  unmount() {
    window.removeEventListener('resize', this.handleDebouncedResize);

    if(this.top && this.main) {
      window.removeEventListener('scroll', this.handleScroll);
    }

    this.navigation.unmount();
    this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
    this.navigationTogglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
      toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
      toggler.unmount();
    });
  }
}
